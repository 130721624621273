import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-services-popup',
  templateUrl: './services-popup.component.html',
  styleUrls: ['./services-popup.component.scss']
})
export class ServicesPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ServicesPopupComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    
  }
  onClose(){
    this.dialogRef.close();
  }

}
