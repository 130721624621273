<h5 class="text-center">{{ data?.name }}</h5>

<div class="text-center mb-3">
  <img [src]="data?.image" [alt]="data?.name" class="img-fluid" />
</div>

<p>{{ data?.description }}</p>

<h6>Benefits:</h6>
<ul class="list-group mb-3">
  <li class="list-group-item" *ngFor="let benefit of data?.benefits">{{ benefit }}</li>
</ul>

<h6>Applications:</h6>
<ul class="list-group">
  <li class="list-group-item" *ngFor="let application of data?.applications">{{ application }}</li>
</ul>
